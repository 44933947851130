import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CardBlockHeader from './CardBlockHeader';
import Card from '../Cards/Card';
import CardStat from '../Cards/CardStat';
import ComponentWrapper from '../ComponentWrapper';
import { variants, variantClasses, linkClasses } from '../../../utils/enums';
import { checkArray, getComponentProps } from '../../../utils/helperUtils';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import './cardblock.scss';

/**
 * @description-  BaseCardBlock component
 * @param {object} props - Input props;
 * @returns{Node} - html block;
 */
const CardBlock = ({ fields, params, isRichText, variant, cssClass }) => {
  const gridValue = 'col-lg-4';
  // checkArray(fields.items).length > 3 ? 'col-lg-3' : 'col-lg-4';
  const updatedParams = (params && readParams(params)) || {};
  const colorSetObj = updatedParams['colorVariations'];
  const buttonColors = updatedParams['linkColor']?.buttonColor?.split('-') || [];

  const colorsArr = colorSetObj?.color.split('-') || [];
  const statColor = { color: colorsArr.length > 1 ? colorsArr[1] : colorsArr[0] };
  const contentColor = { color: colorsArr[0] };

  const stylesObj =
    variant !== variants.CARDBLOCKS.STAT
      ? colorSetObj
      : {
          ...colorSetObj,
          backgroundColor: colorSetObj.backgroundColor,
          color: contentColor.color,
        };

  let classNames = variantClasses.CARDBLOCKS[variant];
  if (cssClass) {
    classNames = classNames + ' ' + cssClass;
  }
  const propVal = getComponentProps(
    { ...updatedParams, colorVariations: stylesObj },
    ['card-block', classNames]
  );
  return (
    <ComponentWrapper {...propVal}>
      <div className="container">
        <CardBlockHeader
          heading={fields?.heading}
          subtitle={fields?.paragraphText}
          variant={variant}
        />
        {variant === variants.CARDBLOCKS.STAT ? (
          <div className="row justify-content-md-center">
            {checkArray(fields.items).map((item) => (
              <div
                key={item.name}
                className={`card-wrapper ${gridValue} col-md-6 col-sm-12`}
              >
                <CardStat
                  stat={item.fields?.heading}
                  text={item.fields?.paragraphText}
                  icon={item.fields?.icon || ''}
                  altText={item.fields?.icon?.value?.alt || ''}
                  statColor={statColor}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="row justify-content-md-center">
            {checkArray(fields.items).map((item) => (
              <div
                key={item.name}
                className={`card-wrapper ${gridValue} col-md-6 col-sm-12`}
              >
                <Card
                  mode={'dark'}
                  {...item.fields}
                  isRichText={isRichText}
                  {...(buttonColors.length > 0 ? { colors: buttonColors } : null)}
                  ctaColor={linkClasses.ORANGE}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </ComponentWrapper>
  );
};

CardBlock.defaultProps = {
  fields: { title: {}, items: [] },
  params: {},
  isRichText: false,
  variant: '',
  cssClass: '',
};

CardBlock.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  params: PropTypes.shape({}),
  isRichText: {
    value: PropTypes.boolean,
  },
  variant: PropTypes.string,
  cssClass: PropTypes.string,
};

export default withTranslation()(CardBlock);
