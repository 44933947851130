import React, { useEffect } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const ExternalSiteEmbed = (props) => {
  const { fields } = props;
  const { text, script, cerosOriginDomains } = fields;
  const scriptUrl = script?.value;

  useEffect(() => {
    if (scriptUrl) {
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.type = 'text/javascript';
      if (cerosOriginDomains?.value) {
        script.setAttribute('data-ceros-origin-domains', cerosOriginDomains?.value);
      }
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptUrl, cerosOriginDomains?.value]);

  return <RichText field={text} />;
};

export default ExternalSiteEmbed;
