/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ModalComponent from '../Modal';
import { stickyHeader } from '../../../utils/helperUtils';

import CountryLinksManpower from './CountryLinksManpower';

import './siteheaderlanguage.scss';

/**
 * @description - to show countrylinks
 * @param {*} param0
 * @returns {HTML} - countrylinks
 */
const SiteHeaderLanguage = (props) => {
  const { countryData, linkItemsManpower } = props;
  const { selected } = countryData?.fields;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal && isMobile()) {
      stickyHeader(document.querySelector('.site-header'));
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showModal]);

  /**
   * to check mobile view or not
   * @returns {Boolean}
   */
  const isMobile = () => {
    let mobileSlider = document.querySelector('.mobile--actives');
    let styles = mobileSlider && getComputedStyle(mobileSlider);
    return styles?.display == 'none' ? true : false;
  };

  /**
   * @description to show job alert dialog
   * @returns {undefined} - nothing
   */
  const handleModal = () => {
    setShowModal(!showModal);
    !showModal
      ? document.getElementsByClassName('country')[0]?.classList.add('rotate-caret')
      : document
          .getElementsByClassName('country')[0]
          ?.classList.remove('rotate-caret');
    // hide sidebar in mobile view
    const sidebarIsActive = document
      .querySelector('.main-nav-slideout')
      ?.classList.contains('active');
    if (isMobile() && sidebarIsActive) {
      document.getElementsByClassName('hamburger-menu-toggle')[0]?.click();
    }
  };

  /**
   * to handle click outside modal
   */
  const handleClickOutside = (event) => {
    if (!document.querySelector('.modal')?.contains(event.target)) {
      setShowModal(false);
    }
  };

  return (
    <div className="country-links">
      <div className="lang-nav">
        {linkItemsManpower &&
          linkItemsManpower.map(
            (item) =>
              item.fields.ctaUrl?.value.href && (
                <div className="link_title">
                  <a
                    aria-label={
                      item.fields.ctaText?.value + ', opens in a new window/tab'
                    }
                    href={item.fields.ctaUrl?.value.href}
                    target={item.fields.ctaUrl?.value.target}
                  >
                    {item.fields.ctaText?.value}
                  </a>
                </div>
              )
          )}
        <div className="language-wrapper">
          <button
            className="country"
            onClick={() => handleModal()}
            aria-label="Country/ Language Selector"
          >
            {Array.isArray(selected) && selected.length > 0 && (
              <>
                <div className="title">{selected[0]?.country}</div>
                {'(' + selected[0]?.language + ')'}
              </>
            )}
          </button>
        </div>
      </div>

      <ModalComponent
        showModal={showModal}
        handleModal={handleModal}
        className="fullscreen"
        backdrop={false}
        removeIndex={true}
      >
        <div key="header" className="container">
          <div onClick={() => handleModal()} className="close" tabIndex="0">
            {''}
          </div>
        </div>
        <div key="body">
          <CountryLinksManpower
            {...countryData}
            hideContent={true}
            autoFocus={true}
          />
        </div>
      </ModalComponent>
    </div>
  );
};

SiteHeaderLanguage.defaultProps = {
  countryData: {},
  linkItemsManpower: [],
  t: () => {},
};

SiteHeaderLanguage.propTypes = {
  countryData: PropTypes.shape({}),
  linkItemsManpower: PropTypes.arrayOf(),
  t: PropTypes.func,
};

export default withTranslation()(SiteHeaderLanguage);
