/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, mediaApi } from '@sitecore-jss/sitecore-jss-react';
import ComponentWrapper from '../ComponentWrapper';
import TextLink from '../globals/link/TextLink';
import ButtonLink from '../globals/link/ButtonLink';
import { useReadParams as readParams } from '../../../hooks/useReadParams';
import {
  separator,
  linkClasses,
  btnClasses,
  ctaVariants,
} from '../../../utils/enums';
import {
  setAltUrlForImg,
  getImg,
  getComponentProps,
  joinStrings,
} from '../../../utils/helperUtils';

import './featurette.scss';

/**
 * @description - featurette component;
 * @param {object}  props - Input props;
 * @returns{Node} - return html block;
 */
const Featurette = (props) => {
  const { fields, params } = props;
  const { backgroundImage } = fields;
  const externalLinkType =
    fields?.ctaUrl?.value?.linktype === 'external' ? true : false;
  const updatedParams = (params && readParams(params)) || {};
  const positionObj = updatedParams['textPosition'] || {};
  const gradientObj = updatedParams['contentPresentationStyle'] || {};
  const imagePresentationObj = updatedParams['imagePresentationStyle'] || {};
  const ctaColor = updatedParams['ctaButtonColor'] || {};
  const ctaTextColor = updatedParams['ctaTextColor'] || {};
  const ctaType = updatedParams['ctaType'] || {};
  const styleBg = {};
  if (backgroundImage?.value?.src) {
    styleBg['backgroundImage'] = `url(${mediaApi.updateImageUrl(
      getImg(backgroundImage)
    )})`;
  }
  const variant = imagePresentationObj?.type || '';
  const isStandard = variant === 'standard';
  let bootstrapGridImage, bootstrapGridContent;
  if (isStandard) {
    if (positionObj['alignText'] === 'left') {
      bootstrapGridImage = 'order-md-2 col-md-5 col-xl-4 col-lg-5';
      bootstrapGridContent =
        'order-md-1 col-xl-6 col-md-6 col-lg-6 offset-xl-1 offset-lg-0';
    } else {
      bootstrapGridImage = 'col-md-5 col-xl-4 col-lg-5 offset-xl-1 offset-lg-0';
      bootstrapGridContent = 'col-xl-6 col-md-6 col-lg-6';
    }
  } else {
    if (positionObj['alignText'] === 'left') {
      bootstrapGridImage = 'order-md-2 col-md-4 col-xl-3 col-lg-4';
      bootstrapGridContent =
        'order-md-1 col-xl-7 col-md-8 col-lg-8 offset-xl-1 offset-lg-0';
    } else {
      bootstrapGridImage = 'col-md-4 col-xl-3 offset-xl-1 col-lg-4 offset-lg-0';
      bootstrapGridContent = 'col-xl-7 col-md-8 col-lg-8';
    }
  }
  const imgClassNames = joinStrings(
    [variant, positionObj?.alignText, gradientObj?.gradient],
    separator.SPACE
  );
  const propVal = getComponentProps(updatedParams, [
    'featurette-section',
    positionObj?.alignText,
    variant,
  ]);
  return (
    <ComponentWrapper {...propVal}>
      <div
        className="featurette-bg-image"
        style={Object.keys(styleBg).length > 0 ? styleBg : {}}
      ></div>
      <div className="container ">
        <div className="row">
          <div className={`${bootstrapGridImage}`}>
            <div className={`featurette-imgs ${imgClassNames}`}>
              {/* <div className="blurred">
                <Image field={setAltUrlForImg(fields.image, '')} />
              </div> */}
              <Image field={setAltUrlForImg(fields.image, '')} />
            </div>
          </div>
          <div className={`${bootstrapGridContent}`}>
            <div className="featurette-body">
              {fields.subTitle && isStandard && (
                <div className="sub-title">
                  <Text field={fields.subTitle} />
                </div>
              )}
              <h2 className="title">
                <Text field={fields.title} />
              </h2>
              {fields.nameText && !isStandard && (
                <div className="name">
                  <Text field={fields.nameText} />
                </div>
              )}
              {fields.subTitle && !isStandard && (
                <p className="job-title">
                  <Text field={fields.subTitle} />
                </p>
              )}
              <p className={isStandard ? 'large' : ''}>
                <Text field={fields.text} />
              </p>
              {ctaType.ctaVariant === ctaVariants.BUTTONLINK ? (
                <ButtonLink
                  cssClass={
                    btnClasses[ctaColor.buttonColor] || btnClasses.PRIMARYORANGE
                  }
                  ctaText={fields?.ctaText}
                  ctaUrl={fields?.ctaUrl}
                  externalLink={externalLinkType}
                  // {...(ctaColor &&
                  //   ctaColor?.buttonColor && {
                  //     cssClass: `primary-button ${ctaColor?.buttonColor}`,
                  //   })}
                />
              ) : (
                <TextLink
                  cssClass={
                    linkClasses[ctaTextColor.textColor] || linkClasses.ORANGE
                  }
                  ctaText={fields?.ctaText}
                  ctaUrl={fields?.ctaUrl}
                  externalLink={externalLinkType}
                  ctaLabel={fields?.ctaText?.value || fields?.ctaUrl?.value?.title}
                  arrow
                />
              )}
              <div className="quote"></div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

Featurette.defaultProps = {
  fields: {
    title: {},
    subTitle: {},
    nameText: {},
    text: {},
    image: { value: { src: 'http://via.placeholder.com/300', alt: 'img' } },
    altText: {},
    ctaText: {},
    ctaUrl: {},
    backgroundColor: {},
    backgroundimage: {},
    mode: { value: 'standard' },
    componentBackgroundColor: [],
  },
  params: {},
};

Featurette.propTypes = {
  fields: PropTypes.shape({
    title: {
      value: PropTypes.string,
    },
    subTitle: {
      value: PropTypes.string,
    },
    nameText: {
      value: PropTypes.string,
    },
    text: {
      value: PropTypes.string,
    },
    image: {
      value: PropTypes.string,
    },
    altText: {
      value: PropTypes.string,
    },
    ctaText: {
      value: PropTypes.string,
    },
    ctaUrl: {
      value: PropTypes.string,
    },
    backgroundColor: {
      value: PropTypes.string,
    },
    backgroundimage: {
      value: PropTypes.string,
    },
    mode: {
      value: PropTypes.oneOf(['standard', 'profile', 'quote']),
    },
    componentBackgroundColor: {
      value: PropTypes.arrayOf(PropTypes.shape({})),
    },
  }),
};

export default Featurette;
