/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable */
import React, { Fragment } from 'react';
import i18n from 'i18next';
import {
  isExperienceEditorActive,
  dataApi,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import config from './temp/config';
import Layout from './Layout';
import ErrorPage from './ErrorPage';
import OverlayLoader from './components/core/ErrorBoundary/OverlayLoader';
import { connect } from 'react-redux';
import { updateLanguage } from './redux/actions';
import { isNullorEmpty, getPageTitle } from './utils/helperUtils';

import { STORAGE } from './constants';
import { layoutServiceDataFetcher } from './layoutServiceDataFetcher';

// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.
/**
 * @returns {*} - any
 */
class RouteHandler extends React.Component {
  /**
   * @constructor
   * @param {*} props - props
   */
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      defaultLanguage: config.defaultLanguage,
      loading: false,
      ssrLoading: false,
      currentLang: config.defaultLanguage,
      unauthorized: false,
      contextFactory: props.contextFactory,
    };

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (routeData !== null) {
      this.state.notFound = false;
      this.state.ssrLoading = true;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (
      routeData &&
      routeData.sitecore.context &&
      routeData.sitecore.context.language
    ) {
      this.state.defaultLanguage = routeData.sitecore.context.language;
    }

    this.componentIsMounted = false;
    this.languageIsChanging = false;

    // tell i18next to sync its current language with the route language
    this.updateLanguage();

    // subscribe to Sitecore context changes
    props.contextFactory.subscribeToContext(this.contextSubscriber);
  }

  contextSubscriber = (context) => {
    if (context?.refresh) {
      let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
      if (!sitecoreRoutePath.startsWith('/')) {
        sitecoreRoutePath = `/${sitecoreRoutePath}`;
      }
      const updatedContext = this.props.sitecoreContext;
      this.props.updateSitecoreContext({
        ...updatedContext,
        refresh: false,
      });
      const routeData = this.state.routeData;
      this.setState({ routeData, notFound: false });
    }
  };

  componentWillUnmount() {
    this.props.contextFactory.unsubscribeFromContext(this.contextSubscriber);
  }

  // If the job is not present in sitecore we must redirect to search page.
  redirectToJobPage = (routeData) => {
    const routeDataInfo = routeData?.sitecore?.route?.name?.toLowerCase() || '';
    const expJobURL = routeData?.sitecore?.context?.Country?.expiredJobSearchURL;
    const queryStrings =
      typeof window !== 'undefined'
        ? new URLSearchParams(window?.location?.search)
        : '';
    const searchText = queryStrings?.get('searchKeyword') || '';
    const searchPlace = queryStrings?.get('place') || '';
    const pageNumber = queryStrings?.get('page') || '';
    const searchMode = queryStrings?.get('mode') || '';
    const jobSearchURL = routeData.sitecore?.context?.Country?.jobSearchURL;
    if (
      !isExperienceEditorActive() &&
      this.props.route?.location &&
      this.props.route?.location?.pathname !== expJobURL &&
      (routeDataInfo === 'start a new job search' || routeDataInfo === 'search')
    ) {
      routeDataInfo !== 'start a new job search'
        ? pageNumber !== '' ||
          searchMode !== '' ||
          searchText !== '' ||
          searchPlace !== ''
          ? ''
          : this.props.route.history.push(jobSearchURL)
        : this.props.route.history.push(expJobURL);
    }
  };

  /**
   *@returns {*} - any
   */
  componentDidMount() {
    this.setUtmParamsFromUrl();
    const routeData = this.extractRouteData();
    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    } else if (routeData) {
      this.redirectToJobPage(routeData);
    }

    // once we initialize the route handler, we've "used up" the SSR data,
    // if it existed, so we want to clear it now that it's in react state.
    // future route changes that might destroy/remount this component should ignore any SSR data.
    // EXCEPTION: Unless we are still SSR-ing. Because SSR can re-render the component twice
    // (once to find GraphQL queries that need to run, the second time to refresh the view with
    // GraphQL query results)
    // We test for SSR by checking for Node-specific process.env variable.
    if (
      typeof window !== 'undefined' &&
      !this.props.ssrRenderComplete &&
      this.props.setSsrRenderComplete
    ) {
      this.props.setSsrRenderComplete(true);
    }

    this.componentIsMounted = true;
  }

  /**
   *@returns {*} -any
   */
  componentWillUnmount() {
    this.setState({ routeData: null });
    this.componentIsMounted = false;
  }

  setUtmParamsFromUrl = () => {
    const docReferer =
      document.referrer !== '' &&
      document.referrer.indexOf(window.location.host) === -1
        ? document.referrer
        : 'direct';
    let UTM_Source = '';
    let UTM_Campaign = '';
    let UTM_Medium = '';
    let UTM_Term = '';
    let UTM_Content = '';
    let Referer = docReferer
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split(/[\/?]+/)[0];
    Referer = Referer.replace(/^[^.]*\.(?=\w+\.\w+$)/g, '');
    const queryStrings = new URLSearchParams(window.location.search);
    if (isNullorEmpty(queryStrings.get('utm_source'))) {
      const sessionQueryParams = new URLSearchParams(
        window.sessionStorage.getItem('searchParams')
      );
      if (!isNullorEmpty(sessionQueryParams.get('utm_source'))) {
        UTM_Source = isNullorEmpty(sessionQueryParams.get('utm_source'))
          ? ''
          : sessionQueryParams.get('utm_source');
        UTM_Campaign = isNullorEmpty(sessionQueryParams.get('utm_campaign'))
          ? ''
          : sessionQueryParams.get('utm_campaign');
        UTM_Medium = isNullorEmpty(sessionQueryParams.get('utm_medium'))
          ? ''
          : sessionQueryParams.get('utm_medium');
        UTM_Term = isNullorEmpty(sessionQueryParams.get('utm_term'))
          ? ''
          : sessionQueryParams.get('utm_term');
        UTM_Content = isNullorEmpty(sessionQueryParams.get('utm_content'))
          ? ''
          : sessionQueryParams.get('utm_content');
        // email as utm source, then referer should set as email
        // as per sarah's comment to set referer as source if no referer domain
        Referer = Referer === 'direct' && UTM_Source !== '' ? UTM_Source : Referer;
      }
      Referer = Referer !== 'direct' && UTM_Source === '' ? Referer : 'direct';
    } else {
      // console.log('session1', sessionQueryParams.get('utm_source'));
      UTM_Source = isNullorEmpty(queryStrings.get('utm_source'))
        ? ''
        : queryStrings.get('utm_source');
      UTM_Campaign = isNullorEmpty(queryStrings.get('utm_campaign'))
        ? ''
        : queryStrings.get('utm_campaign');
      UTM_Medium = isNullorEmpty(queryStrings.get('utm_medium'))
        ? ''
        : queryStrings.get('utm_medium');
      UTM_Term = isNullorEmpty(queryStrings.get('utm_term'))
        ? ''
        : queryStrings.get('utm_term');
      UTM_Content = isNullorEmpty(queryStrings.get('utm_content'))
        ? ''
        : queryStrings.get('utm_content');
      // email as utm source, then referer should set as email
      Referer = Referer === 'direct' && UTM_Source !== '' ? UTM_Source : Referer;

      if (
        queryStrings.get('utm_source') !== null &&
        queryStrings.get('referer') === null
      ) {
        const newpath =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          window.location.search +
          `&referer=${Referer}`;
        window.history.pushState({ path: newpath }, '', newpath);
      }
      Referer = isNullorEmpty(queryStrings.get('referer'))
        ? Referer
        : queryStrings.get('referer');
    }
    // check b2clogin to handle logout- clearing the queryparams
    if (Referer.indexOf('b2clogin') === -1) {
      if (UTM_Source !== '') {
        window.sessionStorage.setItem(
          'utmParams',
          JSON.stringify({
            UTM_Source,
            UTM_Campaign,
            UTM_Medium,
            UTM_Term,
            UTM_Term,
            UTM_Content,
            Referer,
          })
        );
      } else {
        window.sessionStorage.setItem(
          'utmReferrer',
          JSON.stringify({
            UTM_Source,
            UTM_Campaign,
            UTM_Medium,
            UTM_Term,
            UTM_Term,
            UTM_Content,
            Referer,
          })
        );
      }
    }
  };

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;

    const { route, ...context } = this.props.sitecoreContext;

    return {
      sitecore: {
        route,
        context,
      },
    };
  };

  /**
   * @description - Loads route data from Sitecore Layout Service into state.routeData
   * @returns {*} - any
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    const sitcoreRouteQuery = this.props.route.location.search || null;
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }
    const language =
      this.props.route.match.params.lang ||
      this.props.lang ||
      localStorage.getItem('lang') ||
      this.state.defaultLanguage;
    if (
      this.props.route.match.params.lang &&
      this.props.lang !== this.props.route.match.params.lang
    ) {
      this.props.initiateEvents(this.props.route.match.params.lang);
      localStorage.setItem('lang', this.props.route.match.params.lang);
    }

    this.setState({ currentLang: language });
    this.setState({ loading: true });
    // get the route data for the new route
    getRouteData(sitecoreRoutePath, language, sitcoreRouteQuery).then(
      (routeData) => {
        setTimeout(() => {
          document?.getElementById('main')?.scrollIntoView({ behavior: 'smooth' }); // Fix for scroll to top issue on route change.
        }, 0);
        this.setState({ loading: false });
        if (routeData !== null && routeData.sitecore && routeData.sitecore.route) {
          // set the sitecore context data and push the new route
          this.state.contextFactory.setSitecoreContext({
            route: routeData.sitecore.route,
            itemId: routeData.sitecore.route.itemId,
            ...routeData.sitecore.context,
          });
          if (
            window?.dataLayer &&
            this.state?.contextFactory?.context?.pageViewable
          ) {
            sessionStorage.getItem(STORAGE.PAGE_EVENT)
              ? sessionStorage.removeItem(STORAGE.PAGE_EVENT)
              : window.dataLayer.push({
                  event: 'PageLoad',
                  page: `${this.props.route?.location?.pathname}${this.props.route?.location?.search}`,
                  pageTitle: getPageTitle(routeData?.sitecore?.route),
                  user_logged_in: localStorage.getItem(STORAGE.UUID) ? 'yes' : 'no',
                  user_logged_in_id: localStorage.getItem(STORAGE.UUID) || '',
                });
          }
          this.setState({ notFound: false, unauthorized: false });
        } else {
          if (routeData?.unauthorized) {
            this.setState({ unauthorized: true });
          } else {
            this.setState({ notFound: true }, () =>
              this.state.contextFactory.setSitecoreContext(
                routeData?.sitecore?.context
              )
            );
          }
        }
      }
    );
  }

  /**
   * @description - Updates the current app language to match the route data.
   * @returns {*} - any
   */
  updateLanguage() {
    const newLanguage = this.props.route.match.params.lang; // || this.state.defaultLanguage;

    if (i18n.language !== newLanguage && newLanguage !== undefined) {
      this.languageIsChanging = true;

      i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;

        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    }
  }

  /**
   *
   * @param {*} previousProps - previous props
   * @returns {*} - any
   */
  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;
    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isExperienceEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateLanguage();
    this.updateRouteData();
  }
  /**
   *
   * @returns {*} - any
   */
  render() {
    const { loading, currentLang, unauthorized } = this.state;
    const { notFound } = this.state;
    const routeData = this.extractRouteData();
    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    if (
      (notFound &&
        routeData !== null &&
        typeof routeData?.sitecore?.route === 'undefined') ||
      unauthorized
    ) {
      //   window.location.assign('/page-not-found');
      //  const history = useHistory();
      // history.push('/page-not-found');
      return (
        <ErrorPage
          context={routeData?.sitecore?.context}
          currentLang={currentLang}
          unauthorized={unauthorized}
          notFound={notFound}
        />
      );
    }

    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      // return null;
      return <OverlayLoader />;
    }

    // Render the app's root structural layout
    return (
      <Fragment>
        {loading ? <OverlayLoader /> : null}
        <Layout
          route={routeData.sitecore.route}
          brand={routeData.sitecore?.context?.Country?.brandName}
          language={routeData.sitecore?.context?.language}
        />
      </Fragment>
    );
  }
}

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 * @param {string} query - Query params.
 * @returns {Object} - Error Response.
 */
function getRouteData(route, language, query) {
  const queryParam = query || route;
  const id = queryParam ? queryParam.split('id=')?.[1] : null;
  const fetchOptions = {
    layoutServiceConfig: { host: config.sitecoreApiHost },
    querystringParams: { sc_lang: language },
    fetcher: layoutServiceDataFetcher,
    requestConfig: {
      // AxiosRequestConfig -- https://github.com/axios/axios#request-config
      // Note: `withCredentials: true` is added automatically
      timeout: 4000,
    },
  };

  if (id) {
    fetchOptions.querystringParams.id = id;
  }

  return dataApi.fetchRouteData(route, fetchOptions).catch((error) => {
    if (error.response && error.response.status === 404 && error.response.data) {
      return error.response.data;
    }
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      return { data: error.response, unauthorized: true };
    }

    // console.error('Route data fetch error', error, error.response);

    return null;
  });
}
/**
 *
 * @param {*} state - state object
 * @returns {*} - none
 */
const mapStateToProps = (state) => ({
  lang: state.languageReducer.language,
});
/**
 *
 * @param {*} dispatch - dispatch object
 * @returns {*} -none
 */
const mapDispatchToProps = (dispatch) => ({
  initiateEvents: (lang) => dispatch(updateLanguage(lang)),
});

export default withSitecoreContext({ updatable: true })(
  connect(mapStateToProps, mapDispatchToProps)(RouteHandler)
);
