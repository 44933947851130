import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  withPlaceholder,
  withSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';

import SiteFooterNav from '../SiteFooter/SiteFooterNav';
import PlaceholderWrapper from '../globals/placeholderWrapper';
import { variantClasses } from '../../../utils/enums';
import './sitefootercountryselector.scss';
import './sitefooter.scss';

/**
 * @description - Site Footer Container.
 * @param {Object} props - Input props.
 * @returns {Node} - Footer element.
 */
const FooterContainer = (props) => {
  const {
    tabComponents = [],
    disclaimerComponent = [],
    appsComponent = [],
    sitecoreContext,
  } = props;
  const placeholders = sitecoreContext?.route?.placeholders;
  const common = placeholders && placeholders['jss-common'];
  const { items = [] } = (common && common[0]?.fields) || {};
  const [footerContent, setFooterContent] = useState([]);

  useEffect(() => {
    setFooterContent(disclaimerComponent[0]);
  }, [disclaimerComponent]);

  return (
    <footer className="site-footer">
      <div className="site-footer-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <SiteFooterNav menuItems={items} />
            </div>
            <div className="col-lg-4">
              <div className="footer-rightside">
                {tabComponents && <PlaceholderWrapper placeHolder={tabComponents} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* This is being used to display footer disclaimer content */}
      <div className="footer-disclaimer">
        <div className="container">
          <div className="row">
            {footerContent?.props &&
            footerContent?.props?.type === 'text/sitecore' ? (
              footerContent
            ) : (
              <Fragment key="content">{footerContent}</Fragment>
            )}
          </div>
          <div className="row footer-bottom-links">
            {/* This loop is only used to display blank placeholder in experience editor for footer disclaimer */}
            {disclaimerComponent.map((component) => {
              if (component?.props && component?.props?.type === 'text/sitecore')
                return component;
            })}
            {/* This is being used to display footer bottom content (copyrights, social Icons and Counry selector) */}
            {!isExperienceEditorActive() && (
              <>
                {disclaimerComponent.map((component, index) => {
                  if (index > 0) {
                    if (
                      component?.props &&
                      component?.props?.type === 'text/sitecore'
                    )
                      return component;
                    return (
                      <div
                        key={index}
                        className={
                          variantClasses.FOOTERDISCLAIMER[
                            component?.props?.rendering?.componentName
                          ]
                        }
                      >
                        {component}
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>

          <div className="row applinks">
            {/* This loop is only used to display blank placeholder in experience editor for footer apps component */}
            {appsComponent.map((component) => {
              if (component?.props && component?.props?.type === 'text/sitecore')
                return component;
            })}
            {/* This is being used to display footer bottom content (Apple / Android and Trust Apps icons) */}
            {!isExperienceEditorActive() && (
              <>
                {appsComponent.map((component) => {
                  if (component?.props && component?.props?.type === 'text/sitecore')
                    return component;
                  return component;
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

FooterContainer.defaultProps = {
  tabComponents: [],
  disclaimerComponent: [],
  appsComponent: [],
  fields: {},
  sitecoreContext: {},
};

FooterContainer.propTypes = {
  tabComponents: PropTypes.arrayOf(PropTypes.shape()),
  disclaimerComponent: PropTypes.arrayOf(PropTypes.shape({})),
  appsComponent: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({}).isRequired,
  sitecoreContext: PropTypes.shape({}).isRequired,
};

const containerComp = withSitecoreContext()(
  withPlaceholder([
    { placeholder: 'jss-footer-right', prop: 'tabComponents' },
    { placeholder: 'jss-footer-disclaimer', prop: 'disclaimerComponent' },
    { placeholder: 'jss-footer-app', prop: 'appsComponent' },
  ])(FooterContainer)
);

export default containerComp;
